@tailwind base;
@tailwind components;
@tailwind utilities;

// PAGINATION CSS
.pagination-holder {
  margin-top: 32px;

  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;

    li {
      border-radius: 50%;
      background-color: #fff;
      box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.1);

      a {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          width: 21px;
        }
      }

      &.break {
        box-shadow: none;
        align-self: flex-end;
      }

      &.selected {
        background-color: rgb(57, 73, 171);
        color: #fff;
      }
    }
  }
}

// SELECT AUTOMATION
.custom-select-input {
  .css-13cymwt-control {
    border-color: rgb(224, 224, 224) !important;
  }

  .css-t3ipsp-control {
    box-shadow: none !important;
    border-color: #4338ca !important;

    &:hover {
      border-color: #4338ca !important;
    }
  }

  input {
    width: 100% !important;
  }

  [type='text']:focus {
    box-shadow: none !important;
  }
}

// DASHBOARD LAYOUT

.dashboard-layout {
  .sidenav {
    flex-shrink: 0;
    position: sticky;

    .close-sidenav {
      display: none;
    }
  }

  .open-sidenav {
    display: none;
  }
}

@media screen and (max-width: '991.98px') {
  .dashboard-layout {
    .sidenav {
      position: fixed;
      left: -300px;
      z-index: 52;
      &.opened {
        left: 0;
        transition: 0.2s;
      }
      .close-sidenav {
        display: block;
      }
    }

    .open-sidenav {
      display: block;
    }
  }
}

// TOOLTIP
[data-tooltip] {
  position: relative;

  &::before {
    content: attr(data-tooltip);
    position: absolute;
    display: none;
    right: 50%;
    bottom: 100%;
    padding: 4px 8px;
    background-color: #444;
    color: #fff;
    font-size: 12px;
    border-radius: 3px;
  }
  &:hover::before {
    display: block;
  }
}

[positions-tooltip] {
  position: relative;
  &::before {
    content: attr(positions-tooltip);
    position: absolute;
    right: 130%;
    top: -45%;
    display: none;
    padding: 4px 8px;
    background-color: #444;
    color: #fff;
    font-size: 12px;
    border-radius: 3px;
  }
  &:hover::before {
    display: block;
  }
}

.react-datepicker__input-container {
  width: inherit;
}

.react-datepicker-wrapper {
  width: 100%;
}

#dateOfBirth {
  width: 100%;
}

.pagebreak {
  break-after: page;
}
